* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Movie-Container {
  border: 1px solid black;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: auto;
  margin-top: 100px;
  padding: 10px;
  gap: 20px;
  overflow: scroll;
  overflow-x: hidden;
  height: 600px;
  width: 90%;
  min-width: 300px;
}
.Movie-Container .Movie-Card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 500px;
  width: 300px;
  position: relative;
  overflow: hidden;
}
.Movie-Container .Movie-Card .Movie-Image {
  height: 100%;
  width: 100%;
  margin: auto;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.5s;
}
.Movie-Container .Movie-Card .Movie-Detail-Card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: #203538;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  gap: 20px;
  height: 85%;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  transform: translateY(100%);
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Movie-Title {
  color: white;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin: auto;
  margin-top: 0px;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Movie-Subtitle {
  color: white;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: auto;
  margin-top: 0px;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Film-Detail-Box {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  margin-top: 0px;
  margin-left: 0px;
  gap: 10px;
  width: 100%;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Film-Detail-Box .Film-Detail {
  color: white;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin: auto;
  margin-left: 0px;
  transition: 0.5s;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Film-Overview-Box {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 100%;
  gap: 10px;
  width: 100%;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Film-Overview-Box .Film-Overview {
  color: white;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  margin: auto;
  margin-left: 0px;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Type-Box {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Type-Box .Film-Type {
  color: white;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin: auto;
  margin-left: 0px;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Watch-Now-Button {
  display: flex;
  background-color: #f20407;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Watch-Now-Button .Button-Text {
  color: white;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: left;
  margin: auto;
  margin-left: 0px;
}
.Movie-Container .Movie-Card .Movie-Detail-Card .Watch-Now-Button .Button-Text #Button-Exclemation {
  color: white;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 15px;
  text-align: left;
  margin: auto;
  margin-left: 0px;
}
.Movie-Container .Movie-Card:hover .Movie-Image {
  transform: scale(1.2);
}
.Movie-Container .Movie-Card:hover .Movie-Detail-Card {
  transform: translateY(0%);
}
.Movie-Container .Movie-Card:hover .Movie-Detail-Card #Realease {
  color: red;
}
.Movie-Container .Movie-Card:hover .Movie-Detail-Card #Director {
  color: green;
}
.Movie-Container .Movie-Card:hover .Movie-Detail-Card #Main-Cast {
  color: #ffdf00;
}/*# sourceMappingURL=Landing.css.map */